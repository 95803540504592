var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible:
          _vm.$store.state.multimedia_meetingmode.meetingModeSelectPopover,
        "custom-class": "meetingModeSelectPopover",
        "close-on-click-modal": false,
        center: ""
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.multimedia_meetingmode,
            "meetingModeSelectPopover",
            $event
          )
        }
      }
    },
    [
      _vm.showTwo
        ? _c("div", { staticClass: "back", on: { click: _vm.back } }, [
            _c("i", { staticClass: "iconfont_Me iconStyle icon-left" })
          ])
        : _vm._e(),
      _c("transition", { attrs: { name: "transitionStyle" } }, [
        _vm.showOne
          ? _c("div", [
              _c("div", [
                _vm._v(_vm._s(_vm.$t("inThisMeetingChangeDevicesOrJoin")))
              ]),
              _c(
                "div",
                {
                  staticClass: "commonStyle changeButton",
                  on: { click: _vm.replaceEquipment }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("replaceTheDevice")) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "commonStyle sameJoinButton",
                  on: { click: _vm.sameJoin }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("joinAtTheSameTime")) +
                      "\n      "
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "transitionStyle" } }, [
        _vm.showTwo
          ? _c(
              "div",
              { staticClass: "two" },
              [
                _c("WindowHeader", [_vm._v(_vm._s(_vm.$t("setUsername")))]),
                _c(
                  "div",
                  { staticClass: "nicknameInput" },
                  [
                    _c(
                      "CommonInput",
                      {
                        attrs: {
                          placeholderText: _vm.$store.getters.userInfo.name
                        },
                        on: { inputChange: _vm.getNickname }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-user-fill iconStyle"
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "nextStepButton commomButtonHover",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.setNickname.apply(null, arguments)
                      }
                    }
                  },
                  [_c("i", { staticClass: "iconfont_Me icon-next iconStyle" })]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }