<template>
  <!-- 会议模式选择Popover -->
  <el-dialog
    title=""
    :visible.sync="$store.state.multimedia_meetingmode.meetingModeSelectPopover"
    custom-class="meetingModeSelectPopover"
    :close-on-click-modal="false"
    center
  >
    <div v-if="showTwo" class="back" @click="back">
      <i class="iconfont_Me iconStyle icon-left"></i>
    </div>
    <transition name="transitionStyle">
      <div v-if="showOne">
        <div>{{ $t("inThisMeetingChangeDevicesOrJoin") }}</div>
        <div class="commonStyle changeButton" @click="replaceEquipment">
          {{ $t("replaceTheDevice") }}
        </div>
        <div class="commonStyle sameJoinButton" @click="sameJoin">
          {{ $t("joinAtTheSameTime") }}
        </div>
      </div>
    </transition>
    <transition name="transitionStyle">
      <div v-if="showTwo" class="two">
        <WindowHeader>{{ $t("setUsername") }}</WindowHeader>
        <div class="nicknameInput">
          <CommonInput
            @inputChange="getNickname"
            :placeholderText="$store.getters.userInfo.name"
          >
            <span class="iconfont_Me icon-user-fill iconStyle"></span>
          </CommonInput>
        </div>
        <div class="nextStepButton commomButtonHover" @click.stop="setNickname">
          <i class="iconfont_Me icon-next iconStyle"></i>
        </div>
      </div>
    </transition>
  </el-dialog>
</template>
<script>
import * as RESTApi from "@/api/rest";
import WindowHeader from "../../../newVersion/pageViews/loginAndRegister/windowHeader";
import CommonInput from "../../../newVersion/components/inputComponent/commonInput";

export default {
  components: { WindowHeader, CommonInput },
  data() {
    return {
      showOne: true,
      showTwo: false,
    };
  },
  mounted() {
    this.showOne = true;
    this.showTwo = false;
    this.userName = "";
  },
  methods: {
    async replaceEquipment() {
      let params = {
        chatType: "groupChat",
        subjectId: this.$store.state.multimedia_meetingmode.subjectId,
      };
      let result = await RESTApi.exitMeeting(params);
      if (result.code == 200) {
        this.$router.replace({
          path: `/meeting/${this.$store.state.multimedia_meetingmode.meetingRoomId}`,
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    sameJoin() {
      this.showOne = false;
      setTimeout(() => {
        this.showTwo = true;
      }, 300);
    },
    back() {
      this.showTwo = false;
      setTimeout(() => {
        this.showOne = true;
      }, 300);
    },
    // 获取输入的昵称
    getNickname(value) {
      this.userName = value;
    },
    async setNickname() {
      //储存一下新的用户名
      this.$store.commit(
        "setNewAccout",
        this.userName || this.$store.getters.userInfo.name + "(pc)"
      );
      this.$store.commit("setMeetingType", "createNewUser");
      this.$router.replace({
        path: `/meeting/${this.$store.state.multimedia_meetingmode.meetingRoomId}`,
      });
    },
    startQuickJoin_new(data) {
      // 存储会议房间号
      this.$store.commit("setMeetingChannelName", data.meetingInfo.id);
      this.$store.commit("setMeetingGroupId", data.meetingInfo.id);
      // 存储一下新生成的用户ID
      this.$store.commit(
        "setSubstituteUserId",
        data.meetingInfo.substituteUserId
      );
      const members = data.meetingInfo.members;
      members.map((item) => {
        if (item.deviceType == "web") {
          this.$store.commit("setSubstituteUserInfo", item);
        }
      });
      this.$nextTick(() => {
        // 存贮会议发起者的ID
        this.$store.commit("setMeetingCreatorId", data.meetingInfo.creatorId);
        // 设置本次会议开启的时间
        this.$store.commit(
          "setMeetingTimestamp",
          data.sysCurrentTime - data.meetingInfo.meetingTime
        );
        this.$store.commit("setMeetingWindowStatus", true);
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.transitionStyle-enter-active, .transitionStyle-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.transitionStyle-enter {
  opacity: 0;
  transform: translate3d(120px, 0, 0);
}

.transitionStyle-leave-to {
  opacity: 0;
  transform: translate3d(-120px, 0, 0);
}

.dialogHeader {
  width: 100%;
  height: 68px;
  box-sizing: border-box;
  padding: 17px 16px 0 0;

  .iconStyle {
    float: right;
    cursor: pointer;
    font-size: 16px;
    color: #CCCCCC;
  }
}
</style>
<style lang="scss">
.meetingModeSelectPopover {
  width: 448px;
  height: 300px;
  overflow: hidden;

  .el-dialog__body {
    padding-top: 40px;
    text-align: center;

    .commonStyle {
      width: 360px;
      height: 46px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      text-align: center;
      line-height: 46px;
      font-size: 14px;
      user-select: none;
      margin: auto;
    }

    .changeButton {
      margin-top: 40px;
      color: #3c6;
      box-sizing: border-box;
      background: rgba(51, 204, 102, 0.2);
      border: 1px solid rgba(51, 204, 102, 0.7);

      &:hover {
        background: rgba(51, 204, 102, 0.1);
        //   background: #5cd685 !important;
      }
    }

    .sameJoinButton {
      margin-top: 30px;
      color: #fff;
      background: #3c6;
      transition: background 0.3s;

      &:hover {
        background: rgba(51, 204, 102, 0.7);
      }
    }
  }

  .back {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
  }

  .two {
    .nicknameInput {
      margin-top: 40px;
      height: 46px;
    }

    .nextStepButton {
      height: 46px;
      margin-top: 30px;
      border-radius: 4px;
      background: #33cc66;
      display: flex;
      align-items: center;
      justify-content: center;

      .iconStyle {
        font-size: 24px;
        color: #ffffff;
      }
    }
  }
}
</style>
